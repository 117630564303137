
import {
  ShaderMaterial,
  Color,
} from "three"

import { Text } from "troika-three-text"
import GSAP from 'gsap'

import canvas from "lib/canvas"
import fragment from './_fragment.glsl'
import vertex from './_vertex.glsl'

import { 
  TScroll,
  IProjectsItemGl
} from 'data/'

import mirthaItalic from "fonts/MirthaDisplayItalic.woff"
import mirthaRegular from "fonts/MirthaDisplayRegular.woff"

interface ProjectsItem extends IProjectsItemGl {}

class ProjectsItem  {
  constructor({ domElement, uid, totalHeight, scene })  {
    
    this.domElement = domElement
    this.uid = uid
    this.scene = scene

    this.totalHeight = totalHeight

    this.scrollPosition = 0

    this.options = {
      toUpdate: true,
      isHide: false,
      isBefore: null,
      isAfter: null
    }

    this.glElements = {
      geometry: new Text(),
      material: new ShaderMaterial(),
    }

    this.header = document.querySelector('#header')

    this.init()
  }


  init() {
    this.bind()
    this.createBounds()
    this.createGeometry()
    this.createMaterial()
    this.addToScene()
  }

  bind() {
    this.setPositions = this.setPositions.bind(this)
    this.initForAnimations = this.initForAnimations.bind(this)
    this.update = this.update.bind(this)
    this.destroy = this.destroy.bind(this)
    this.onResize = this.onResize.bind(this)
    this.onLoaded = this.onLoaded.bind(this)
  }

  // ------------------------------------------------ SETUP

  createBounds() {
    const bounds = this.domElement.getBoundingClientRect()

    this.bounds = {
      extra: 0,
      top: bounds.top,
      left: bounds.left,
      width: bounds.width,
      height: bounds.height,
      canvasWidth: canvas.container.offsetWidth,
      canvasHeight: canvas.container.offsetHeight
    }
  }

  createGeometry() {
    this.glElements.geometry.text = `${this.domElement.innerText}`
    this.glElements.geometry.font = this.uid !== null ? mirthaRegular : mirthaItalic

    this.glElements.geometry.textAlign = getComputedStyle(this.domElement).textAlign
    
    this.glElements.geometry.anchorX = 'center'
    this.glElements.geometry.anchorY = 'middle'

    this.glElements.geometry.fontSize = parseFloat(getComputedStyle(this.domElement).fontSize)
    this.glElements.geometry.lineHeight = this.domElement.getAttribute('line-height')
    this.glElements.geometry.sdfGlyphSize = 32
    this.glElements.geometry.name = this.uid
  }

  createMaterial() {
    this.glElements.material.uniforms ={
      u_viewportSizes: { value: [window.innerWidth, window.innerHeight] },
      u_color: { value : new Color(getComputedStyle(this.domElement).color)},
      u_alpha: { value : 1.0 },
      u_strength: { value: 0.0 },
      u_textLength: { value: 0.0}
    }
    this.glElements.material.vertexShader = vertex
    this.glElements.material.fragmentShader = fragment
    this.glElements.geometry.material = this.glElements.material
    this.glElements.geometry.layers.set(1)
  }

  initForAnimations() {
    this.glElements.geometry.position.z = this.uid !== null ? -50 : 0
    this.glElements.material.uniforms.u_alpha.value = this.uid !== null ? 0.0 : 1.0
    this.options.isHide = this.uid !== null ? true : false
  }

  addToScene() {
    this.scene.add(this.glElements.geometry)
  }

  onLoaded(promise) {
    this.glElements.geometry.sync()
    this.glElements.geometry.addEventListener('synccomplete', () => {
      this.glElements.geometry.sdfGlyphSize = 512
      promise()
    })
  }

  // ------------------------------------------------ METHODS
  setColor(theme : string) {
    const initialColor = theme === "outerSpace" ? new Color('#283f38') : new Color('#f5cdd5')
    const newColor = theme === "outerSpace" ? new Color('#f5cdd5') : new Color('#283f38')
    const timeline = GSAP.timeline()

    timeline.to(initialColor, {
      r: newColor.r,
      g: newColor.g,
      b: newColor.b,
      onUpdate: () => {
        this.glElements.geometry.material.uniforms.u_color.value.r = initialColor.r
        this.glElements.geometry.material.uniforms.u_color.value.g = initialColor.g
        this.glElements.geometry.material.uniforms.u_color.value.b = initialColor.b
      }
    })
    return timeline
  }
  center() {

    const timeline = GSAP.timeline({
      onStart: () => {
        this.options.toUpdate = false
        this.options.isHide = false
      }
    })

    timeline.to(this.glElements.geometry.position, {
      x : 0,
      y: (this.bounds.canvasHeight / 2) - (this.bounds.height / 2) - 100,
    })
    if (this.glElements.geometry.material.uniforms.u_alpha.value < 1) {
      timeline.to(this.glElements.geometry.material.uniforms.u_alpha, {
        value : 1.0,
      },0)
    }
  }


  hide() {
    const timeline = GSAP.timeline({
      onComplete: () => {
        this.options.isHide = true
      }
    })

    timeline.to(this.glElements.geometry.position, {
      z : -50,
    })

    timeline.to(this.glElements.geometry.material.uniforms.u_alpha, {
      value : 0.0,
    },0)

    return timeline
  }

  show() {
    const timeline = GSAP.timeline({
      onComplete: () => {
        this.options.isHide = false
      }
    })
    timeline.to(this.glElements.geometry.position, {
      z : 0,
    },0)
    timeline.to(this.glElements.geometry.material.uniforms.u_alpha, {
      value : 1.0,
    },0)
  }

  reveal() {
    const timeline = GSAP.timeline({
      onComplete: () => {
        this.options.isHide = false
      }
    })

    timeline.to(this.glElements.geometry.position, {
      z: 0
    },0)

    timeline.to(this.glElements.geometry.material.uniforms.u_alpha, {
      value: 1.0
    },0)

    return timeline
  }

  setVisibility(visibility) {
    if (visibility) {
      this.options.toUpdate = false
      this.options.isHide = false
      this.glElements.material.uniforms.u_alpha.value = 1.0
      this.glElements.geometry.position.x = 0
      this.glElements.geometry.position.y = (this.bounds.canvasHeight / 2) - (this.bounds.height / 2)
    } else {
      this.options.isHide = true
      this.glElements.material.uniforms.u_alpha.value = 0.0
    }
  }

  // ------------------------------------------------ SETTER
  setAlpha(value: number) {
    this.glElements.geometry.material.uniforms.u_alpha.value = value
  }

  setPositionZ(value: number) {
    this.glElements.geometry.position.z = value
  }

  // ------------------------------------------------ EVENTS
  onResize(totalHeight) {
    this.bounds.extra = 0
    this.totalHeight = totalHeight

    this.createBounds()
    this.glElements.geometry.fontSize = parseFloat(getComputedStyle(this.domElement).fontSize)
  }

  // ------------------------------------------------ POSITIONS

  setPositions(y = 0) {
    this.glElements.geometry.position.x = this.bounds.left - this.bounds.canvasWidth / 2 + this.bounds.width / 2
    this.glElements.geometry.position.y =  -this.bounds.top - y + this.bounds.canvasHeight / 2 - this.bounds.height / 2 + this.bounds.extra
  }

  resetPosition() {
    return GSAP.to(this.glElements.geometry.position, {
      x : this.bounds.left - this.bounds.canvasWidth / 2 + this.bounds.width / 2,
      y: -this.bounds.top - this.scrollPosition + this.bounds.canvasHeight / 2 - this.bounds.height / 2 + this.bounds.extra,
      onComplete: () => {
        this.options.toUpdate = true
      }})
  }

  getPosition() {
    return -this.bounds.top - this.scrollPosition + this.bounds.canvasHeight / 2 - this.bounds.height / 2 + this.bounds.extra
  }

  // ------------------------------------------------ RAF
  update(y: TScroll, direction: string) {
    if (!this.options.toUpdate) return

    this.setPositions(y.current)

    this.scrollPosition = y.current

    const limitTop = (this.bounds.canvasHeight / 2) + (this.bounds.height / 2)
    const limitBottom = -((this.bounds.canvasHeight / 2) + (this.bounds.height / 2))

    this.options.isBefore = this.glElements.geometry.position.y > limitTop
    this.options.isAfter = this.glElements.geometry.position.y < limitBottom

    if (direction === 'up' && this.options.isBefore) {
      this.bounds.extra -= this.totalHeight
      this.options.isBefore = false
      this.options.isAfter = false
    }

    if (direction === 'down' && this.options.isAfter) {
      this.bounds.extra += this.totalHeight
      this.options.isBefore = false
      this.options.isAfter = false
    }

    this.glElements.material.uniforms.u_strength.value = ((y.current - y.last) / 2) * 10
  }

  // ------------------------------------------------ DESTROY
  destroy() {
    this.scene.remove( this.glElements.geometry )
  }
}

export default ProjectsItem


import React, { useEffect, useState, ReactNode } from "react"
import TransitionLink from 'gatsby-plugin-transition-link'
import Scroll from 'lib/scroll'
import *  as scss from './ContextualTransitionLink.module.scss'
import { getProjectsUid } from "hooks/getProjectsUid"

import canvas from "lib/canvas"

interface Iprops {
  children: ReactNode
  location: any
}

const ContextualTransitionLink = ({children, location} : Iprops) => {

  
  // ------------------------------------------------ STATE
  const [target, setTarget] = useState('/')
  const projectsUid = getProjectsUid()
  // let projectsDetails = new RegExp(`/${this.regexWords}`)

  // ------------------------------------------------ CONFIG
  const exit = {
    trigger: () => {
      Scroll.disable()
    },
    length: 0.05
  }

  const entry = {
    trigger: () => {
      Scroll.reset()
    },
    delay: 0.1
  }

  useEffect(() => {
    let projectsDetails = new RegExp(`/${projectsUid}`)
    if (location === '/projects') setTarget('/')
    if (projectsDetails.test(location)) setTarget('/projects')
  }, [location])

  return (
    <TransitionLink to={target} className={scss.root} exit={exit} entry={entry}>
      {children}
    </TransitionLink>
  )
}

export default ContextualTransitionLink
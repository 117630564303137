import React, { useEffect, useRef, useContext } from "react"
import GSAP from 'gsap'
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "components/globalContextProvider/GlobalContextProvider"
import *  as scss from './Header.module.scss'
import DefaultTransitionLink from "components/defaultTransitionLink/DefaultTransitionLink"
import ContextualTransitionLink from "components/contextualTransitionLink/ContextualTransitionLink"
import Hud from "componentsWebgl/hud/Hud"

const componentName = 'Header'

const Header = ( { location } ) => {

  // ------------------------------------------------ CONTEXT
  const dispatch = useContext(GlobalDispatchContext)
  const globalState = useContext(GlobalStateContext)

  // ------------------------------------------------ DATA
  const slice = globalState.hud

  // ------------------------------------------------ REFS
  const refWrapper = useRef()
  const refTitle = useRef()
  const refSubTitle = useRef()
  const refColorSwitch = useRef()
  const refButton = useRef()

  // ------------------------------------------------ METHODS
  const handleClick = () => {
    dispatch( { type: "TOGGLE_THEME" } )
  }

    // ------------------------------------------------ FIND & HANDLE COMPONENTS WEBGL
  const handleWebgl =  () => {
    if (slice) {
      const object = new Hud({
        title: refTitle.current,
        colorSwitch: refColorSwitch.current,
        backButton: refButton.current,
        subTitle: refSubTitle.current
      })
      slice.webgl = object
    }
  }

    // ------------------------------------------------ USEEFFECT WEBGL
    useEffect(() => {
      // globalState.webgl && handleWebgl()
      // return () => {
      //   globalState.webgl && slice.webgl.destroy()
      // }
    }, [globalState.webgl])

    // ------------------------------------------------ USEEFFECT THEME
    useEffect(() => {
      const timeline = GSAP.timeline()
      timeline.to([refWrapper.current, refTitle.current, refButton.current],{
        color: globalState.theme === 'wePeep' ? '#283f38' : '#f5cdd5',
      })

      if (globalState.theme === 'wePeep') {
        refButton.current.classList.add('backButton--wePeep')
        refButton.current.classList.remove('backButton--outerSpace')
      } else {
        refButton.current.classList.add('backButton--outerSpace')
        refButton.current.classList.remove('backButton--wePeep')
      }
      
    }, [globalState.theme])

  // ------------------------------------------------ USEEFFECT LOCATION
  useEffect(() => {
    const timeline = GSAP.timeline({})      
    if (location != '/') {
      timeline.to(refButton.current, {
        autoAlpha: 1,
        pointerEvents: 'auto'
      })
    } else {
      timeline.to(refButton.current, {
        autoAlpha: 0,
        pointerEvents: 'none'
      })
    }
  }, [location])

  return (
    <div className={scss.root} ref={refWrapper}>
      <div className={scss.top} id='header'>
        <DefaultTransitionLink target={'/'}>
          <div ref={refTitle} className={scss.title}>Eddy Naboulet</div>
        </DefaultTransitionLink>
        <div onClick={handleClick} className={scss.colorSwap} ref={refColorSwitch}>*</div>
      </div>
      <div className={scss.bottom}>
        <ContextualTransitionLink location={location}>
          <div ref={refButton} className='backButton'>Back</div>
        </ContextualTransitionLink>
        <div ref={refSubTitle}>Freelance developer</div>
      </div>
    </div>
  )
}

export default Header
exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-lab-lab-tsx": () => import("./../../../src/templates/lab/Lab.tsx" /* webpackChunkName: "component---src-templates-lab-lab-tsx" */),
  "component---src-templates-page-page-tsx": () => import("./../../../src/templates/page/Page.tsx" /* webpackChunkName: "component---src-templates-page-page-tsx" */),
  "component---src-templates-project-project-tsx": () => import("./../../../src/templates/project/Project.tsx" /* webpackChunkName: "component---src-templates-project-project-tsx" */)
}


  
interface MouseMove {
  callbacks: {
    name: string
    callback: Function
  }[]
}

class MouseMove {

  constructor() {
    this.callbacks = []
    this.bind()
  }

  init() {
    this.addEventsListeners()
  }
  
  // ------------------------------------------------ SUBSCRIBE
  subscribe(name : string, callback : Function) {
    this.callbacks.push({
      name: name,
      callback: callback
    })

  }
  
  unsubscribe(name : string) {
    this.callbacks.forEach((item, i) => {
      if (item.name == name) this.callbacks.splice(i, i + 1)
    })
  }

  // ------------------------------------------------ EVENTS
  onMouseMove(event: TouchEvent & MouseEvent) {
    this.callbacks.forEach(item => item.callback(event) )
  }

  addEventsListeners() {
    if (!window)return
    window.addEventListener('mousemove', this.onMouseMove)
    window.addEventListener('touchmove', this.onMouseMove)
  }

  // ------------------------------------------------ BIND
  bind() {
    this.onMouseMove = this.onMouseMove.bind(this)
  }
}

const _instance = new MouseMove()
export default _instance
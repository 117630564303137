import React, { Fragment, useEffect, useRef, useContext, useState } from 'react'
import * as scss from './Layout.module.scss'
import GSAP from 'gsap'
import { GlobalStateContext } from "components/globalContextProvider/GlobalContextProvider"

import PromisesAdministrator from 'lib/promisesAdministrator'

import { SkipNavLink } from 'components/skipNav/SkipNav'
import { SEO } from 'components/seo/Seo'
import Preloader from 'components/preloader/Preloader'
import Header from 'components/header/Header'
import Navigation from 'components/navigation/Navigation'

import Canvas from 'lib/canvas'
import Resize from 'lib/eventsResize'
import MouseMove from 'lib/eventMouseMove'

const componentName = 'Layout'

const Layout = ({ children, location, pageContext}) => {
  
  // ------------------------------------------------ CONTEXT
  const globalState = useContext(GlobalStateContext)

  // ------------------------------------------------ REFS
  const refWrapper = useRef()
  const refContainer = useRef()

  // ------------------------------------------------ USEEFFECT ONCE
  useEffect(() => {
    Canvas.init(refContainer.current)
    Resize.init()
    MouseMove.init()
  }, [])

  // ------------------------------------------------ USEEFFECT WEBGL
  useEffect(() => {
    if (globalState.webgl) {
      PromisesAdministrator.resolveTextsPromises()
    }
  }, [globalState.webgl])

    // ------------------------------------------------ USEEFFECT THEME
    useEffect(() => {
      const timeline = GSAP.timeline()
      timeline.to(refContainer.current, {
        backgroundColor: globalState.theme === "outerSpace" ? '#283f38' : '#f5cdd5'
      })
    }, [globalState.theme])

    useEffect(() => {
      if (location.pathname === '/about') {
        document.querySelector('body').style.position = 'relative'
        document.querySelector('html').style.position = 'relative'
      } else {
        document.querySelector('body').style.position = 'fixed'
        document.querySelector('html').style.position = 'fixed'
      }
    }, [location])
  
  // ------------------------------------------------ RENDER
  return (
    <Fragment>
      <SkipNavLink/>
      <SEO/>
      <Preloader/>
      <Header location={location.pathname} />
      <Navigation location={location.pathname}/>
      <section id='reach-skip-nav'>
        <div asscroll-container='true' className={scss.root} ref={refWrapper}>
          <div className='scroll-wrap'>
            { children }
          </div>
        </div>
      </section>
      <div id='container' ref={refContainer}></div>
    </Fragment>
  )
}

export default Layout

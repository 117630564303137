
import {
  ShaderMaterial,
  Color,
  Scene
} from "three"

import { Text } from "troika-three-text"
import GSAP from 'gsap'

import scroll from "lib/scroll"
import canvas from "lib/canvas"
import fragment from './_fragment.glsl'
import vertex from './_vertex.glsl'

import { 
  IMenuItemGl
} from 'data/'

import mirthaItalic from "fonts/MirthaDisplayItalic.woff"

interface MenuItem extends IMenuItemGl {}

class MenuItem  {
  constructor( { domElement, uid, scene } )  {
    
    this.domElement = domElement
    this.uid = uid
    this.scene = scene

    this.options = {
      toUpdate: true,
      isHide: false,
      isBefore: null,
      isAfter: null,
      iscurrent: false
    }

    this.targetPosition = {
      value: 0
    }

    this.glElements = {
      geometry: new Text(),
      material: new ShaderMaterial(),
    }

    this.init()
  }


  init() {
    this.bind()
    this.createBounds()
    this.createGeometry()
    this.createMaterial()
    this.addToScene()
  }

  initForAnimations() {
    this.glElements.geometry.position.z = -50
    this.glElements.material.uniforms.u_alpha.value = 0.0
    this.options.isHide = true
  }

  bind() {
    this.setPosition = this.setPosition.bind(this)
    this.destroy = this.destroy.bind(this)
    this.onResize = this.onResize.bind(this)
    this.onLoaded = this.onLoaded.bind(this)
  }

  // ------------------------------------------------ SETUP

  createBounds() {
    const bounds = this.domElement.getBoundingClientRect()

    this.bounds = {
      top: bounds.top,
      left: bounds.left,
      width: bounds.width,
      height: bounds.height,
      canvasWidth: canvas.container.offsetWidth,
      canvasHeight: canvas.container.offsetHeight
    }

    if (!this.options.isCurrent) this.targetPosition.value = -this.bounds.top + this.bounds.canvasHeight / 2 - this.bounds.height / 2
  }

  createGeometry() {
    this.glElements.geometry.text = `${this.domElement.innerText}`
    this.glElements.geometry.font = mirthaItalic

    this.glElements.geometry.textAlign = getComputedStyle(this.domElement).textAlign
    
    this.glElements.geometry.anchorX = 'center'
    this.glElements.geometry.anchorY = 'middle'

    this.glElements.geometry.fontSize = parseFloat(getComputedStyle(this.domElement).fontSize)
    this.glElements.geometry.lineHeight = this.domElement.getAttribute('line-height')
    this.glElements.geometry.sdfGlyphSize = 32
  }

  createMaterial() {
    this.glElements.material.uniforms ={
      u_viewportSizes: { value: [window.innerWidth, window.innerHeight] },
      u_color: { value : new Color(getComputedStyle(this.domElement).color)},
      u_alpha: { value : 1.0 },
      u_strength: { value: 0.0 },
      u_textLength: { value: 0.0}
    }
    this.glElements.material.vertexShader = vertex
    this.glElements.material.fragmentShader = fragment
    this.glElements.geometry.material = this.glElements.material
    this.glElements.geometry.layers.set(1)
  }

  addToScene() {
    this.scene.add(this.glElements.geometry)
  }

  onLoaded(promise: Function) {
    this.glElements.geometry.sync()
    this.glElements.geometry.addEventListener('synccomplete', () => {
      this.glElements.geometry.sdfGlyphSize = 512
      promise()
    })
  }

  // ------------------------------------------------ METHODS
  setColor(theme : string) {
    const initialColor = theme === "outerSpace" ? new Color('#283f38') : new Color('#f5cdd5')
    const newColor = theme === "outerSpace" ? new Color('#f5cdd5') : new Color('#283f38')
    const timeline = GSAP.timeline()

    timeline.to(initialColor, {
      r: newColor.r,
      g: newColor.g,
      b: newColor.b,
      onUpdate: () => {
        this.glElements.geometry.material.uniforms.u_color.value.r = initialColor.r
        this.glElements.geometry.material.uniforms.u_color.value.g = initialColor.g
        this.glElements.geometry.material.uniforms.u_color.value.b = initialColor.b
      }
    })
    return timeline
  }

  center() {
    const timeline = GSAP.timeline()
    timeline.to(this.glElements.geometry.position, {
      x : 0,
    },0)
    timeline.to(this.targetPosition, {
      value : 0,
    },0)
    return timeline
  }


  hide() {
    const timeline = GSAP.timeline({
      onStart: () => {
        this.options.isHide = true
      }
    })
    timeline.to(this.glElements.geometry.position, {
      x : 0,
    },0)
    timeline.to(this.targetPosition, {
      value : 0,
    },0)
    timeline.to(this.glElements.geometry.material.uniforms.u_alpha, {
      value : 0.0,
    },0)
    return timeline
  }

  show() {
    const timeline = GSAP.timeline({
      defaults: {
        duration: 0.3
      },
      onComplete: () => {
        this.options.isHide = false
      }
    })
    timeline.to(this.glElements.geometry.position, {
      z : 0,
    },0)
    timeline.to(this.glElements.geometry.material.uniforms.u_alpha, {
      value : 1.0,
    },0)
    timeline.to(this.glElements.geometry.position, {
      x : this.bounds.left - this.bounds.canvasWidth / 2 + this.bounds.width / 2,
    },0)
    timeline.to(this.targetPosition, {
      value : -this.bounds.top + this.bounds.canvasHeight / 2 - this.bounds.height / 2,
    },0)
  }

  setPositions() {
    const timeline = GSAP.timeline( { onComplete: () => this.options.isHide = false } )
    timeline.set( this.glElements.geometry.position, { z : 0 } )
    timeline.set( this.glElements.geometry.material.uniforms.u_alpha, { value : 1.0 } )
    timeline.set( this.glElements.geometry.position, { x : this.bounds.left - this.bounds.canvasWidth / 2 + this.bounds.width / 2 } )
    timeline.set(this.targetPosition, { value : -this.bounds.top + this.bounds.canvasHeight / 2 - this.bounds.height / 2 } )
  }

  isCurrent() {
    this.options.isCurrent = true
    this.options.isHide = false
    this.glElements.material.uniforms.u_alpha.value = 1.0
    this.targetPosition.value = 0

  }

  setVisibility(visibility) {
    if (visibility) {
      this.options.isHide = false
      this.glElements.material.uniforms.u_alpha.value = 1.0
    } else {
      this.options.isHide = true
      this.glElements.material.uniforms.u_alpha.value = 0.0
    }
  }

  // ------------------------------------------------ EVENTS
  onResize() {
    this.createBounds()
    this.glElements.geometry.fontSize = parseFloat(getComputedStyle(this.domElement).fontSize)
  }

  // ------------------------------------------------ SETTER
  setAlpha(value: number) {
    this.glElements.geometry.material.uniforms.u_alpha.value = value
  }

  setPositionZ(value: number) {
    this.glElements.geometry.position.z = value
  }

  // ------------------------------------------------ POSITIONS
  setPosition() {
    this.glElements.geometry.position.x = this.bounds.left - this.bounds.canvasWidth / 2 + this.bounds.width / 2
    this.glElements.geometry.position.y = (scroll.ASScroll.currentPos) - (-(this.targetPosition.value))
  }

  resetPosition() {

    const timeline = GSAP.timeline({
      ease: "power2.out",
      onComplete: () => this.options.isCurrent = false
    })

    timeline.to(this.glElements.geometry.position, {
      x : this.bounds.left - this.bounds.canvasWidth / 2 + this.bounds.width / 2,
    },0)
    timeline.to(this.targetPosition, {
      value : -this.bounds.top + this.bounds.canvasHeight / 2 - this.bounds.height / 2,
    },0)
  }

  // ------------------------------------------------ RAF

  update() {
    this.setPosition()
  }

  // ------------------------------------------------ DESTROY
  destroy() {
    this.scene.remove( this.glElements.geometry )
  }
}

export default MenuItem
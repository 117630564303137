import { graphql, useStaticQuery } from "gatsby"

import { IMultiplePageTemplateData } from '../data/dataStruct'

interface Props  {
  page_template: {
    edges: IMultiplePageTemplateData[]
  }
}

export const getPagesTemplate = () => {
  const data = useStaticQuery<Props>(graphql`
    query {
      pages: allPrismicPageTemplate {
        nodes {
          uid
          data {
            body {
              ... on PrismicPageTemplateDataBodyClientsList {
                id
              }
              ... on PrismicPageTemplateDataBodyLabList {
                id
              }
              ... on PrismicPageTemplateDataBodyProjectsList {
                id
              }
              ... on PrismicPageTemplateDataBodyText {
                id
              }
              ... on PrismicPageTemplateDataBodyContact {
                id
              }
            }
          }
        }
      }
    }
  `)

  return data.pages.nodes
}
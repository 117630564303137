import { graphql, useStaticQuery } from "gatsby"
import { IGetPagesForMenu } from 'data/dataStruct'

interface Props  extends IGetPagesForMenu {}

export const getProjectsUid = () => {
  const data = useStaticQuery<Props>(graphql`
    query getProjectsUid {
      projects: allPrismicProjectTemplate {
        nodes {
          uid
        }
      }
    }
  `)

  let projectsUid = ''

  data.projects.nodes.forEach((project, index) => {
    projectsUid += index === 0 ? `${project.uid}` : `|${project.uid}`
  })


  return projectsUid
}


import { graphql, useStaticQuery } from "gatsby"
import { IGetPagesForMenu } from 'data/dataStruct'

interface Props  extends IGetPagesForMenu {}

export const getProjectsList = () => {
  const data = useStaticQuery<Props>(graphql`
    query getProjectsList {
      projects: prismicProjectsPage {
        ...projectsPageDataProjectsList
      }
    }
  `)
  return data.projects
}


import React, { useEffect, useState, ReactNode } from "react"
import TransitionLink from 'gatsby-plugin-transition-link'
import Scroll from 'lib/scroll'
import *  as scss from './DefaultTransitionLink.module.scss'

const componentName = 'DefaultTransitionLink'

interface Iprops {
  children: ReactNode
  target: string
}

const DefaultTransitionLink = ({children, target} : Iprops) => {

  // ------------------------------------------------ CONFIG
  const exit = {
    trigger: () => {
      Scroll.disable()
    },
    length: 0.05
  }

  const entry = {
    trigger: () => {
      Scroll.reset()
    },
    delay: 0.1
  }

  return (
    <TransitionLink to={target} className={scss.root} exit={exit} entry={entry}>
      {children}
    </TransitionLink>
  )
}

export default DefaultTransitionLink
import { graphql, useStaticQuery } from "gatsby"
import { IGetPagesForMenu } from 'data/dataStruct'

interface Props  extends IGetPagesForMenu {}

export const getPagesForMenu = () => {
  const data = useStaticQuery<Props>(graphql`
    query getPagesForMenu {
      pages: prismicHomePage {
        ...homePageDataMenu
      }
    }
  `)
  return data.pages
}


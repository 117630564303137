import React, { createContext, useReducer } from "react"

const initialState = {
  theme: "outerSpace",
  webgl: false,
  loaded: false,
  textures: [],
  pages_template_slices: [],
  mainTitlesLayout: {
    webgl: null
  },
  hud: {
    webgl: null
  },
  projectDetails: {
    webgl: null
  },
  projectsList: {
    webgl: null
  },
  aboutText: {
    webgl: null
  }
}

export const GlobalStateContext = createContext(null)
export const GlobalDispatchContext = createContext(null)


function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_THEME": {
      return {
        ...state,
        theme: state.theme === "outerSpace" ? "wePeep" : "outerSpace",
      }
    }
    case "TOGGLE_WEBGL": {
      return {
        ...state,
        webgl: true,
      }
    }

    case "TOGGLE_LOADED": {
      return {
        ...state,
        loaded: true,
      }
    }

    case "PUSH_TEXTURES": {
      return {
        ...state,
        textures: action.textures
      }
    }

    case "PUSH_PAGES_TEMPLATE_SLICES": {
      return {
        ...state,
        pages_template_slices: action.pagesTemplate
      }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        { children }
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
import React, { useEffect, useContext, useRef, useState} from "react"
import GSAP from 'gsap'
import {
  LoadingManager,
  TextureLoader,
} from "three"
import EventEmitter from "events"
import PromiseAdministrator from 'lib/promisesAdministrator'

import *  as scss from './Preloader.module.scss'
import canvas from 'lib/canvas'
import { getPagesTemplate } from "hooks/getPagesTemplate"
import {getPageTemplateWebglSlice} from 'lib/arrayUtils'
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "components/globalContextProvider/GlobalContextProvider"
import Scroll from 'lib/scroll'

import texturePlaceholder from 'textures/texture-placeholder.jpg'

const componentName = 'Preloader'

const Preloader = () => {
  
  // ------------------------------------------------ DATA
  const pagesTemplateData = getPagesTemplate()

  // ------------------------------------------------ STATE
  const [event, setEvent] = useState(new EventEmitter())

  // ------------------------------------------------ REFS
  const refPreloader = useRef<HTMLDivElement>()

  // ------------------------------------------------ CONTEXT
  const dispatch = useContext(GlobalDispatchContext)
  const globalState = useContext(GlobalStateContext)

  // ------------------------------------------------ HANDLE EVENTS
  const loadedEvent = () => {
    dispatch({ type: "TOGGLE_LOADED" })
    canvas.revealCurrentPass()
  }

  // ------------------------------------------------ USEEFFECT WEBGL
  useEffect(() => {
    if (globalState.loaded) {
      GSAP.to(refPreloader.current, {
        autoAlpha: 0,
        pointerEvents: 'none'
      })
    }
  }, [globalState.loaded])

  // ------------------------------------------------ USEEFFECT ONCE
  useEffect(() => {
    dispatch({
      type: "PUSH_PAGES_TEMPLATE_SLICES",
      pagesTemplate : getPageTemplateWebglSlice(pagesTemplateData)
    })

    event.on('loaded', loadedEvent)
    PromiseAdministrator.init(event)

    const loadingManager = new LoadingManager(
      () => {
        Scroll.init()
        dispatch({ type: "TOGGLE_WEBGL" })
      },  
      (itemUrl, itemsLoaded, itemsTotal) => {
        // console.log(`${itemsLoaded} on ${itemsTotal}`)
      }
    )
    const textureLoader = new TextureLoader(loadingManager)
    const textures = []

    textures.push({
      name: 'placeholder',
      texture: textureLoader.load(texturePlaceholder)
    })

    dispatch({ type: "PUSH_TEXTURES", textures })

  }, [])
  
  return (
    <div className={scss.root} ref={refPreloader}>Loading <span>☺</span></div>
  )
}

export default Preloader
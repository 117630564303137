// import { IMultiplePageTemplateData } from "../data/dataStruct"
import {
  TextureLoader
} from "three"

export const merge = (pClasses: any[], pJoin: string = " "): string => {
  if (pClasses?.length > 0) {
    return pClasses
      .reduce((a, b) => a.concat(b), [])
      .filter((v) => v)
      .join(pJoin)
  }
}

export const getPageTemplateWebglSlice = (pages) => {
  return pages.reduce((obj, item) => {
    obj[item.uid] = {}
    obj[item.uid]['slices'] = item.data.body.reduce((slice, content) => {
      slice[content.id] = {
        webgl: null
      }
      return slice
    }, {})
    return obj
  }, {})
}


class PromisesAdministrator {

  constructor() {

    this.textsPromises = []
    this.componentsPromises = []
    this.event = null
  }

  init(event) {
    this.event = event
  }

  createPromise(item) {
    let promise = new Promise((resolve, reject) => {
      item.onLoaded(resolve)
    })
    this.textsPromises.push(promise)
  }

  resolveTextsPromises() {
    Promise.all(this.textsPromises).then(() => {
      this.event.emit('loaded')
    })
  }

}

const _instance = new PromisesAdministrator()
export default _instance
import React, { useEffect, useContext, useRef, useState } from "react"
import EventEmitter from "events"
import { navigate } from "gatsby"

import *  as scss from './Navigation.module.scss'

import {
  GlobalStateContext,
} from "components/globalContextProvider/GlobalContextProvider"

import NavigationGl from 'componentsWebgl/navigation/Navigation'

import { getProjectsList } from "hooks/getProjectsList"
import { getPagesForMenu } from "hooks/getPagesForMenu"

const componentName = 'ProjectsList'
interface Iprops {
  location: string
}
const ProjectsList = ( { location } : Iprops) => {

  // ------------------------------------------------ STATE
  const [event, setEvent] = useState(new EventEmitter())

  // ------------------------------------------------ CONTEXT
  const globalState = useContext(GlobalStateContext)

  // ------------------------------------------------ DATA
  const slice = globalState.projectsList

  const { data: { projects_list } } = getProjectsList()
  const { data: { menu } } = getPagesForMenu()

  const projects = [...projects_list]
  // const projects = [...projects_list, ...projects_list]

  const projectsListArray = projects.map( ( { project }, index ) => {
    const { uid, id, data: { title } } = project.document
    return (
      <div 
      className={scss.text}
      key={`projectsTitle--${uid}-${index}`}
      id={`${id}`}
      draggable={false}>
        <span ref={(item) => addToRefsTexts(item,uid)}>{title}</span>
    </div>
    )
  })
  
  projectsListArray.splice(2, 0, (
    <div className={scss.text} draggable={false} key={`projectsTitle--projectsMain`} >
      <span ref={(item) => addToRefsTexts(item,null)}>Projects</span>
    </div>
  ))


  // ------------------------------------------------ REFS
  const refContainer = useRef<HTMLDivElement>()
  const refInnerContainer = useRef<HTMLDivElement>()
  const refTextsProjects = useRef([])
  refTextsProjects.current = []
  const refTextsMenu = useRef([])
  refTextsMenu.current = []

  const addToRefsTexts = (item: HTMLHeadElement, uid: string) => {
    if (item && !refTextsProjects.current.includes(item)) {
      refTextsProjects.current.push({
        text: item,
        uid: uid
      })
    }
  }

  const addToRefsTextsMenu = (item: HTMLHeadElement, uid: string) => {
    if (item && !refTextsMenu.current.includes(item)) {
      refTextsMenu.current.push({
        text: item,
        uid: uid
      })
    }
  }

  // ------------------------------------------------ FIND & HANDLE COMPONENTS WEBGL
  const handleWebgl =  () => {
    if (slice)   {
      const object = new NavigationGl({
        currentLocation: location,
        event: event,
        domElements: {
          container: refContainer.current,
          menu: refTextsMenu.current,
          projects: refTextsProjects.current
        }
      })
      slice.webgl = object
    }
  }
  // ------------------------------------------------ HANDLE EVENTS
  const customEvent = (event : string) => {
    if (window.location.pathname === '/projects' || window.location.pathname === '/projects/') navigate(`/projects/${event}`)
    else navigate(`/${event}`)
  }

  // ------------------------------------------------ USEEFFECT WEBGL
  useEffect(() => {
    globalState.webgl && handleWebgl()
    return () => {
      globalState.webgl && slice.webgl.destroy()
    }
  }, [globalState.webgl])

  // ------------------------------------------------ USEEFFECT LOCATION
  useEffect(() => {
    if (slice.webgl) {
      slice.webgl.setLocation(location)
    }
  }, [location])

  // ------------------------------------------------ USEEFFECT THEME
  useEffect(() => {
    if (slice && slice.webgl) {
      slice.webgl.setTheme(globalState.theme)
    }
  }, [globalState.theme])

  // ------------------------------------------------ USEEFFECT ONCE
  useEffect(() => {
    event.on('navigate', customEvent)

    return () => {
      event.removeListener('navigate', customEvent)
    }
  }, [])
  
  return (
    <div className={scss.root} ref={refContainer}>
      <div className={scss.container} ref={refInnerContainer}>
        {projectsListArray} 
      </div>
      <div className={scss.container}>
        {
            menu.map( ( { link } ) => {
              const { uid, id, data: { title } } = link.document
              return (
                <div 
                  className={scss.text}
                  key={`mainTitlesLayout--${id}`}
                  id={`${id}`}
                  draggable={false}>
                    <h2 ref={(item) => addToRefsTextsMenu(item,uid)}>{title}</h2>
                </div>
                )
            })
          }
      </div>
    </div>
  )
}

export default ProjectsList
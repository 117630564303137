
import {
  Vector2,
  Vector4,
  Clock,
  DataTexture,
  Color,
  RGBFormat,
  FloatType,
  NearestFilter
} from "three"
import GSAP from 'gsap'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { lerp } from "lib/math"

import fragment from './_fragment.glsl'
import vertex from './_vertex.glsl'

import canvas from "lib/canvas"


import { IPixelDistortion } from 'data/'

function clamp(number, min, max) {
  return Math.max(min, Math.min(number, max));
}
interface PixelDistortion extends IPixelDistortion {}

class PixelDistortion {
  constructor( { effectComposer } ) {
    this.effectComposer = effectComposer

    this.texture = null

    this.settings = {
      grid: 34,
      mouse: 0.05,
      strength: 1.0,
      relaxation: 1.0,
    }

    this.mouse = {
      x: 0,
      y: 0,
      prevX: 0,
      prevY: 0,
      vX: 0,
      vY: 0
    }

  }
  // ------------------------------------------------ INIT
  init() {
    this.createShader()
    this.addShader()
    this.regenerateGrid()
    this.bind()
  }

  // ------------------------------------------------ CREATE SHADER
  createShader() {
    this.shader = {
      uniforms:
      {
        u_dataTexture: {
          value: this.texture
        },
        tDiffuse: { value: null },
        u_factor: { value: 0.15 },
        u_alpha: { value: 1.0 }
      },
      vertexShader: vertex,
      fragmentShader: fragment
    }
  }

  // ------------------------------------------------ ADD SHADER
  addShader() {
    this.shaderPass = new ShaderPass( this.shader )
    this.shaderPass.renderToScreen = false
    this.effectComposer.addPass( this.shaderPass )
  }

  bind() {
    this.update = this.update.bind(this)
    this.destroy = this.destroy.bind(this)
  }

  setFactor() {
    this.shaderPass.material.uniforms.u_factor.value = 1
  }

  regenerateGrid() {
    const width = this.settings.grid
    const height = this.settings.grid
    const size = width * height
    const data = new Float32Array(3 * size)

    for (let i = 0; i < size; i++) {
      let r = Math.random() * 255 - 125
      let r1 = Math.random() * 255 - 125

      const stride = i * 3

      data[stride] = r
      data[stride + 1] = r1
      data[stride + 2] = r
    }

    this.texture = new DataTexture(data, width, height, RGBFormat, FloatType)

    this.texture.magFilter = this.texture.minFilter = NearestFilter

    this.shaderPass.material.uniforms.u_dataTexture.value = this.texture
    this.shaderPass.material.uniforms.u_dataTexture.value.needsUpdate = true
  }

  reveal() {
    const timeline = GSAP.timeline()

    timeline.to(this.settings, {
      relaxation: 0.96,
      duration: 1
    })

    return timeline
  }

  hide() {
    const width = this.settings.grid
    const height = this.settings.grid
    const size = width * height

    this.settings.relaxation = 1.0
    this.shaderPass.material.uniforms.u_factor.value = 0.15

    const timeline = GSAP.timeline({})
    
    for (let i = 0; i < size; i++) {
      // let r = Math.random() * 255 - 125;
      // let r1 = Math.random() * 255 - 125;
      let r = Math.random() * GSAP.utils.random(-130, 130)
      let r1 = Math.random() * GSAP.utils.random(-130, 130)

      const stride = i * 3;

      let value = {
        a : this.texture.image.data[stride],
        b : this.texture.image.data[stride + 1],
        c : this.texture.image.data[stride + 2],
      }

      timeline.add(() => {
        GSAP.to(value, {
          a: r,
          b: r1,
          c: r,
          duration: 0.5,
          ease: "power2.out",
          onUpdate:() => {
            this.texture.image.data[stride] = value.a
            this.texture.image.data[stride + 1] = value.b
            this.texture.image.data[stride + 2] = value.c
            // this.texture.image.data[stride + GSAP.utils.random(1, 50)] = value.b
            // this.texture.image.data[stride + GSAP.utils.random(1, 50)] = value.c
          }
        })
      })
    }

    timeline.to(this.shader.uniforms.u_alpha, {
      value: 0
    })

    this.texture.needsUpdate = true
    
    return timeline
  }

  show() {
    return GSAP.to(this.settings, {
      relaxation: 0.90
    })
  }

  // ------------------------------------------------ RAF
  updateDataTexture() {
    let data = this.texture.image.data

    for (let i = 0; i < data.length; i += 3) {
      data[i] *= this.settings.relaxation
      data[i + 1] *= this.settings.relaxation
    }

    let gridMouseX = this.settings.grid * this.mouse.x
    let gridMouseY = this.settings.grid * (1 - this.mouse.y)

    let maxDist = this.settings.grid * this.settings.mouse
    let aspect = canvas.container.offsetHeight / canvas.container.offsetWidth

    for (let i = 0; i < this.settings.grid; i++) {
      for (let j = 0; j < this.settings.grid; j++) {

        let distance = ((gridMouseX - i) ** 2) / aspect + (gridMouseY - j) ** 2
        let maxDistSq = maxDist ** 2

        if (distance < maxDistSq) {

          let index = 3 * (i + this.settings.grid * j)

          let power = maxDist / Math.sqrt(distance)
          power = clamp(power, 0, 10)
          // if(distance <this.settings.grid/32) power = 1
          // power = 1

          data[index] += this.settings.strength * 100 * this.mouse.vX * power
          data[index + 1] -= this.settings.strength * 100 * this.mouse.vY * power
        }
      }
    }

    this.mouse.vX *= 0.9
    this.mouse.vY *= 0.9
    this.texture.needsUpdate = true
  }

  update() {
    this.updateDataTexture()
  }

  updateMouse(x: number , y: number) {
    this.mouse.x = x / canvas.container.offsetWidth
    this.mouse.y = y / canvas.container.offsetHeight

    this.mouse.vX = this.mouse.x - this.mouse.prevX
    this.mouse.vY = this.mouse.y - this.mouse.prevY

    this.mouse.prevX = this.mouse.x
    this.mouse.prevY = this.mouse.y
  }

  // ------------------------------------------------ DESTROY
  removeShader() {
    this.effectComposer.removePass(this.shaderPass)
  }

  destroy() {
    this.removeShader()
  }
}

export default PixelDistortion
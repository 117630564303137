import GSAP from 'gsap'
import canvas from "lib/canvas"
import Navigation from './Navigation'

import { 
  IDefaultClickEvent,
  IDefaultNavigationElements
} from 'data/'

export function fromHomeToProjects(this: Navigation) {
  const timeline = GSAP.timeline({
    onStart: () => this.glElements.scene.projects.visible = true,
    onComplete: () => {
      this.options.autoScroll = true
      this.glElements.scene.menu.visible = false
    }
  })

  timeline.add( canvas.currentPass.hide() )
  this.projectsElements.items.forEach( ( item ) => timeline.add( item.reveal(), 0.15 ) )
  timeline.add( canvas.currentPass.show(), 0.2 )
  this.menuElements.items.forEach( ( item ) => item.uid !== 'projects' && timeline.add( item.hide(), 0.15 ) )
}

export function fromProjectsToHome(this: Navigation) {
  const timeline = GSAP.timeline({
    onStart: () => {
      this.options.isClicked.projects = false
      this.options.autoScroll = false
    },
    onComplete: () => {
      this.glElements.scene.projects.visible = false
    }
  })

  let height = this.getlistHeight(this.domElements.projects)
  let v = height * Math.round(this.scroll.target / height)

  // timeline.to(this.scroll, {
  //   duration: 0.5,
  //   target: v
  // })

  timeline.add( () => this.glElements.scene.menu.visible = true, 0.5 )
  timeline.add( canvas.currentPass.hide() )
  this.menuElements.items.forEach( ( item ) => timeline.add( item.show() ) )
  timeline.add( canvas.currentPass.show(), 0.2 )
  this.projectsElements.items.forEach( ( item ) => item.uid !== 'projects' && timeline.add( item.hide(), 0.5 ) )
}

export function fromHomeToPages(this: Navigation, location: string) {
  let currentItem = this.menuElements.items.find( ( item : IDefaultNavigationElements ) => `/${item.uid}` === location )
  const timeline = GSAP.timeline()
  
  timeline.add( canvas.currentPass.hide() )
  timeline.add( currentItem.center(), 0.2 )
  timeline.add( canvas.currentPass.show(), 0.2 )

  this.menuElements.items.forEach( ( item ) => {
    if (`/${item.uid}` !== location) {
      timeline.add( item.hide(), 0.3 )
    }
  })
}

export function fromPagesToHome(this: Navigation) {
  const timeline = GSAP.timeline()

  timeline.add( canvas.currentPass.hide() )

  this.menuElements.items.forEach( ( item  ) => item.options.isHide ? timeline.add( item.show() ) : timeline.add( item.resetPosition() ) )

  timeline.add( canvas.currentPass.show())
}

export function fromProjectDetailToProjects(this: Navigation) {
  const timeline = GSAP.timeline({
    onStart: () => this.options.isClicked.projects = false,
    onComplete: () => this.options.autoScroll = true
  })

  timeline.add( canvas.currentPass.hide() )
  this.projectsElements.items.forEach((item) => {
    !item.options.isHide ? timeline.add(item.resetPosition()) : timeline.add(item.show())
  })
  timeline.add( canvas.currentPass.show(), 0.2 )
}

export function fromProjectstoProjectDetail(this: Navigation, location) {
  let itemCount = 0
  let currentItem = this.projectsElements.items.find( ( item ) => location.includes(item.uid) )
  const timeline = GSAP.timeline({
    onStart: () => this.options.isClicked.projects = true
  })

  timeline.add( canvas.currentPass.hide() )
  timeline.add( canvas.currentPass.show(), 0.2 )

  this.projectsElements.items.forEach( ( item ) => {
    if (location.includes(item.uid) && itemCount === 0) {
      timeline.add( currentItem.center(), 0.2 )
      itemCount = 1
    } else if (location.includes(item.uid) && itemCount > 0) {
      timeline.add( item.hide(), 0.3 )
    } else {
      timeline.add( item.hide(), 0.3 )
    }
  })
}

export function fromProjectDetailToHome(this: Navigation) {
  const timeline = GSAP.timeline({
    onStart: () => {
      this.glElements.scene.menu.visible = true
    },
    onComplete: () => {
      this.options.isClicked.projects = false
      this.glElements.scene.projects.visible = false
    }
  })

  timeline.add( canvas.currentPass.hide() )
  this.projectsElements.items.forEach((item) => {
    !item.options.isHide ? timeline.add(item.resetPosition()) : timeline.add(item.show())
  })
  this.menuElements.items.forEach( ( item ) => timeline.add( item.show() ) )
  timeline.add( canvas.currentPass.show(), 0.2 )
  this.projectsElements.items.forEach( ( item  ) => item.uid !== 'projects' && timeline.add( item.hide(), 0.5 ) )
}

export function fromHomeToProjectDetail(this: Navigation, location) {
  let itemCount = 0

  let currentItem = this.projectsElements.items.find( ( item ) => location.includes(item.uid) )

  const timeline = GSAP.timeline({
    onStart: () => {
      this.glElements.scene.projects.visible = true
      this.options.isClicked.projects = true
    },
    onComplete: () => this.glElements.scene.menu.visible = false
  })

  timeline.add( canvas.currentPass.hide() )
  timeline.add( canvas.currentPass.show(), 0.2 )
  this.menuElements.items.forEach( ( item ) => timeline.add( item.hide(), 0.3 ) )
  this.projectsElements.items.forEach( ( item ) => {
    if (location.includes(item.uid) && itemCount === 0) {
      timeline.add( currentItem.center(), 0.2 )
      itemCount = 1
    } else if (location.includes(item.uid) && itemCount > 0) {
      timeline.add( item.hide(), 0.3 )
    } else {
      timeline.add( item.hide(), 0.3 )
    }
  })
}

export const handleClickMenuNavigation = ( { items, event } : IDefaultClickEvent ) => {
  items.raycasterItems.forEach((object, index) => {
    if (object === items.currentIntersect.object) {
      if (items.items[index].uid !== null) {
        event.emit('navigate',  items.items[index].uid)
      }
    } 
  })
}
